<template>
  <div class="bg-light">
    <div class="container py-md-5 py-3">
      <div class="p-md-5 p-3 row">
        <h2 class="font-weight-bold">Our Board of Directors</h2>
        <!-- <p class="font-weight-light">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea
        </p> -->
      </div>
              <div class=" px-md-5 px-3 row">

        <div class=" col-lg-4 col-md-6 col-sm-12 container_foto p-2">
          <router-link class="text-decoration-none" to="/team/Ndaba-Ntsele">
            <div class="card h-100 border-0">
              <img
                src="../../assets/images/team/ndaba/ndabaNtsele1.jpg"
                class="card-img-top"
                alt="..."
              />
              <div
                class="card-body pamodzi-red-background text-center pamodzi-cream"
              >
                <h2 class="h5">Ndaba Ntsele</h2>
                <h4 class="small">Executive Chairman</h4>
                <button class="btn pamodzi-black-backgropund text-white">
                  View More
                </button>
              </div>
            </div>
          </router-link>
        </div>
        <div class=" col-lg-4 col-md-6 col-sm-12  container_foto p-2">
          <router-link class="text-decoration-none" to="/team/Goodluck-Phumzile-Tshelane">
            <div class="card h-100 border-0">
              <img
                src="../../assets/images/team/phumzile-tselane/phumzile.jpg"
                class="card-img-top"
                alt="..."
              />
              <div
                class="card-body pamodzi-red-background text-center pamodzi-cream"
              >
                <h2 class="h5">Phumzile Tshelane</h2>
                <h4 class="small">Executive Director</h4>
                <button class="btn pamodzi-black-backgropund text-white">
                  View More
                </button>
              </div>
            </div>
          </router-link>
        </div>
        <div class=" col-lg-4 col-md-6 col-sm-12  container_foto p-2">
          <router-link class="text-decoration-none" to="/team/Dupree-Vilakazi">
            <div class="card h-100 border-0">
              <img
                src="../../assets/images/team/dupree/dupree.jpg"
                class="card-img-top"
                alt="..."
              />
              <div
                class="card-body pamodzi-red-background text-center pamodzi-cream"
              >
                <h2 class="h5">Dupree Vilakazi</h2>
                <h4 class="small">Director</h4>
                <button class="btn pamodzi-black-backgropund text-white">
                  View More
                </button>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="p-md-5 p-3 row">
        <h2 class="font-weight-bold">Our executive team</h2>
        <p class="font-weight-light">
          <!-- Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea -->
        </p>
      </div>
      <div>
        <div class=" px-md-5 px-3 row">
          <div class=" col-lg-6 col-md-6 col-sm-12 container_foto p-2">
            <router-link class="text-decoration-none" to="/team/Ndaba-Ntsele">
              <div class="card h-100 border-0">
                <img
                  src="../../assets/images/team/ndaba/ndabaNtsele1.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div
                  class="card-body pamodzi-red-background text-center pamodzi-cream"
                >
                  <h2 class="h5">Ndaba Ntsele</h2>
                  <h4 class="small">Executive Chairman</h4>
                  <button class="btn pamodzi-black-backgropund text-white">
                    View More
                  </button>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 p-2">
            <router-link
              class="text-decoration-none"
              to="/team/Mothejwa-Sethunya"
            >
              <div class="card h-100 border-0">
                <img
                  src="../../assets/images/team/mothejwa/mothejwa.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div
                  class="card-body pamodzi-red-background text-center pamodzi-cream"
                >
                  <h2 class="h5">Mothejwa Sethunya</h2>
                  <h4 class="small">
                    Pamodzi Group HR
                  </h4>
                  <button class="btn pamodzi-black-backgropund text-white">
                    View More
                  </button>
                </div>
              </div>
            </router-link>
          </div>

        <!-- <div class=" col-lg-4 col-md-4 col-sm-12  container_foto p-2">
          <router-link class="text-decoration-none" to="/team/Siyanda-Mngadi">
            <div class="card h-100 border-0">
              <img
                src="../../assets/images/team/siyanda/siyanda.jpg"
                class="card-img-top"
                alt="..."
              />
              <div
                class="card-body pamodzi-red-background text-center pamodzi-cream"
              >
                <h2 class="h5">Siyanda Mngadi</h2>
                <h4 class="small">Pamodzi Resources - CEO /Pamodzi FAW – CEO</h4>
                <button class="btn pamodzi-black-backgropund text-white">
                  View More
                </button>
              </div>
            </div>
          </router-link>
        </div> -->
        </div>
      </div>
      <div class="p-md-5 p-3 row">
        <h2 class="font-weight-bold">Divisional CEO's</h2>
        <p class="font-weight-light">
          <!-- Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea -->
        </p>
      </div>
      <div class=" px-md-5 px-3 row">
        <div class=" col-lg-6 col-md-6 col-sm-12 container_foto p-2">
          <router-link
            class="text-decoration-none"
            to="/team/Noluthando-Ntsele"
          >
            <div class="card h-100 border-0">
              <img
                src="../../assets/images/team/thando/thando.jpg"
                class="card-img-top"
                alt="..."
              />
              <div
                class="card-body pamodzi-red-background text-center pamodzi-cream"
              >
                <h2 class="h5">Noluthando Ntsele</h2>
                <h4 class="small">Group Legal/PRES CEO</h4>
                <button class="btn pamodzi-black-backgropund text-white">
                  View More
                </button>
              </div>
            </div>
          </router-link>
        </div>
        <!-- <div class="col-lg-4 col-md-4 col-sm-12 container_foto p-2">
          <router-link class="text-decoration-none" to="/team/Sindisiwe-Dlamini">
            <div class="card h-100 border-0">
              <img
                src="../../assets/images/team/sindisiwe-dlamini/sindisiwe3.jpg"
                class="card-img-top"
                alt="..."
              />
              <div
                class="card-body pamodzi-red-background text-center pamodzi-cream"
              >
                <h2 class="h5">Sindisiwe Dlamini</h2>
                <h4 class="small">
                  Deputy Chairperson/Pamodzi Unique Engineering - CEO
                </h4>
                <button class="btn pamodzi-black-backgropund text-white">
                  View More
                </button>
              </div>
            </div>
          </router-link>
        </div> -->
        <div class="col-lg-6 col-md-6 col-sm-12 p-2">
            <router-link
              class="text-decoration-none"
              to="/team/Yang-Alex-Wang"
            >
              <div class="card h-100 border-0">
                <img
                  src="../../assets/images/team/yang-alex-wang/yang.jpg"
                  class="card-img-top"
                  alt="..."
                />
                <div
                  class="card-body pamodzi-red-background text-center pamodzi-cream"
                >
                  <h2 class="h5">Yang Alex Wang</h2>
                  <h4 class="small">
                    Project Development Officer
                  </h4>
                  <button class="btn pamodzi-black-backgropund text-white">
                    View More
                  </button>
                </div>
              </div>
            </router-link>
          </div>
        <!-- <div class="col-lg-3 col-md-4 col-sm-12 p-2">
          <router-link class="text-decoration-none" to="/team/Ruan-Koen">
            <div class="card h-100 border-0">
              <img
                src="../../assets/images/team/ruan/ruan.jpg"
                class="card-img-top"
                alt="..."
              />
              <div
                class="card-body pamodzi-red-background text-center pamodzi-cream"
              >
                <h2 class="h5">Ruan Koen</h2>
                <h4 class="small">Pamodzi Rail - CEO</h4>
                <button class="btn pamodzi-black-backgropund text-white">
                  View More
                </button>
              </div>
            </div>
          </router-link>
        </div> -->
      </div>
      <div class="p-md-5 p-3 row">
        <h2 class="font-weight-bold">Our Divisional General Managers</h2>
        <p class="font-weight-light">
          <!-- Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea -->
        </p>
      </div>
      <div class=" px-md-5 px-3 row">
        <!-- <div class=" col-lg-3 col-md-4 col-sm-12  container_foto p-2">
          <router-link class="text-decoration-none" to="/team/Luzuko-Ntsele">
            <div class="card h-100 border-0">
              <img
                src="../../assets/images/team/luzuko/luzuko.jpg"
                class="card-img-top"
                alt="..."
              />
              <div
                class="card-body pamodzi-red-background text-center pamodzi-cream"
              >
                <h2 class="h5">Luzuko Ntsele</h2>
                <h4 class="small">Group Business Analyst/Petroleum GM</h4>
                <button class="btn pamodzi-black-backgropund text-white">
                  View More
                </button>
              </div>
            </div>
          </router-link>
        </div> -->
        <!-- <div class=" col-lg-3 col-md-4 col-sm-12   container_foto p-2">
          <router-link class="text-decoration-none" to="/team/Mabora-Manamela">
            <div class="card h-100 border-0">
              <img
                src="../../assets/images/team/mabora/mabora.jpg"
                class="card-img-top"
                alt="..."
              />
              <div
                class="card-body pamodzi-red-background text-center pamodzi-cream"
              >
                <h2 class="h5">Mabora Manamela</h2>
                <h4 class="small ">PRES - GM/Pamodzi FAW – GM</h4>
                <button class="btn pamodzi-black-backgropund text-white">
                  View More
                </button>
              </div>
            </div>
          </router-link>
        </div> -->
        <div class=" col-lg-6 col-md-6 col-sm-12 container_foto p-2">
          <router-link class="text-decoration-none" to="/team/Ayavuya-Madolo">
            <div class="card h-100 border-0">
              <img
                src="../../assets/images/team/ayavuya/ayavuya.jpg"
                class="card-img-top"
                alt="..."
              />
              <div
                class="card-body pamodzi-red-background text-center pamodzi-cream"
              >
                <h2 class="h5">Ayavuya Madolo</h2>
                <h4 class="small">
                  Group Sales and Marketing/Pamodzi Smart Stores - GM
                </h4>
                <button class="btn pamodzi-black-backgropund text-white">
                  View More
                </button>
              </div>
            </div>
          </router-link>
        </div>
        <div class=" col-lg-6 col-md-6 col-sm-12 container_foto p-2">
          <router-link class="text-decoration-none" to="/team/Thami-Nompula">
            <div class="card h-100 border-0">
              <img
                src="../../assets/images/team/thami/thami.png"
                class="card-img-top"
                alt="..."
              />
              <div
                class="card-body pamodzi-red-background text-center pamodzi-cream"
              >
                <h2 class="h5 ">Thami Nompula</h2>
                <h4 class="small">Group Business Development Executive</h4>
                <button class="btn pamodzi-black-backgropund text-white">
                  View More
                </button>
              </div>
            </div>
          </router-link>
        </div>
        <!-- <div class=" col-lg-3 col-md-4 col-sm-12  container_foto p-2">
          <router-link class="text-decoration-none" to="/team/Seggie-Kitsamy">
            <div class="card h-100 border-0">
              <img
                src="../../assets/images/team/seggie-kitsamy/seggie.jpg"
                class="card-img-top"
                alt="..."
              />
              <div
                class="card-body pamodzi-red-background text-center pamodzi-cream"
              >
                <h2 class="h5">Seggie Kitsamy</h2>
                <h4 class="small">Pamodzi Petroleum - COO</h4>
                <button class="btn pamodzi-black-backgropund text-white">
                  View More
                </button>
              </div>
            </div>
          </router-link>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamExecutives",
};
</script>

<style scoped lang="css">
.btn {
  opacity: 0;
  visibility: hidden;
  transition: 1s;
}

.card:hover .btn {
  opacity: 1;
  visibility: visible;
  color: red;
}

.card-img-top {
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: top center;
}
</style>
