<template>
  <div class="container text-center">
    <div v-if="currenciesUsed==[]" class="">
      <div class="spinner-grow spinner-grow-lg" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else class="">
      <h3>South African Rand Vs Other Currencies</h3>
      <Carousel :settings="settings" :breakpoints="breakpoints">
        <Slide v-for="currency in currenciesUsed" v-bind:key="currency">
          <div class="carousel__item p-3">
            <blockquote class="blockquote text-center">
              <p class="mb-0">{{ removeFirstPart(currency.title) }}</p>
              <p class="mb-0">R{{ currency.vsRand }}</p>
              <p class="blockquote-footer">{{ currency.pubDate }}</p>
            </blockquote>
          </div>
        </Slide>
        <template class="carousel__prev carousel__next" #addons>
          <Navigation/>
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>

// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css';
import {Carousel, Navigation, Slide} from 'vue3-carousel';

const axios = require('axios').default;

export default {
  name: "CurrencyCarousel",
  components: {
    Carousel,
    Slide,
    Navigation
  },
  data: () => ({
    currencies: ['USD', 'YEN', 'EUR', 'GBP', 'BWP','AUD','CAD','AED','BRL','INR','RUB','NGN'],
    currenciesUsed: [],
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
    },
  })
  , methods: {
    fetchData: function () {
      axios.get(process.env.VUE_APP_ROOT_API + '/currency.php')
          .then((response) => {
            this.feedData = response.data.channel.item;
            this.logResults('currencyData', this.feedData);
            this.setCurrencies(this.currencies);
          })
    },
    logResults: function (name, res) {
      console.log(name, res)
    },
    getSecondPart: function (str) {
      return str.split('= ')[1].split(' ')[0];
    },
    removeFirstPart: function (str) {
      return str.split('/')[1]},
    setCurrencies: function (currencyArray) {

      currencyArray.forEach(res => {
        var found = false;
        for (let i = 0; i < this.feedData.length; i++) {
          if (this.feedData[i].title.includes(res)) {
            this.feedData[i].vsRand = (1 / parseFloat(this.getSecondPart(this.feedData[i].description))).toFixed(2)
            this.currenciesUsed.push(this.feedData[i])
            found = true;
            break;
          }
        }
      })

      console.log(this.currenciesUsed);
    }
  },
  beforeMount() {
    this.fetchData();
  }
}

</script>

<style scoped>
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: white;
  color: black;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__prev {
  background-color: #9B1D00 !important;
}

.carousel__next {
  background-color: #9B1D00 !important;

}

</style>
