<template>
  <div class="about">
    <NavATF route-id="about"></NavATF>
    <AboutMissionAndVision></AboutMissionAndVision>
    <AboutRoots></AboutRoots>
    <AboutTimeline class="d-md-none p-1"></AboutTimeline>
    <AboutRootsDesktop class="d-none d-md-block p-1"></AboutRootsDesktop>
    <SubsidiariesMore></SubsidiariesMore>
  </div>
</template>
<script>
import AboutMissionAndVision from "@/components/about/AboutMissionAndVision";
import AboutRoots from "@/components/about/AboutRoots";
import NavATF from "@/components/shared/NavATF";
import SubsidiariesMore from "@/components/subsidiaries/SubsidiariesMore";
import AboutTimeline from "@/components/about/AboutTimeline";
import AboutRootsDesktop from "@/components/AboutRootsDesktop";

export default {
  name: 'About',
  components: {AboutRootsDesktop, AboutTimeline, SubsidiariesMore, NavATF, AboutRoots, AboutMissionAndVision},
  created() {
    document.title = "Pamodzi | About Us"
  }
}
</script>
