<template>
  <div class="pamodzi-grey-backgropund">
    <div class="text-left container pt-md-5 pt-3 ">
      <div class="row">
        <div class="col-md-8">
          <h2 class="text-uppercase pamodzi-red heading-size">Our roots</h2>
          <p class="pt-3 font-weight-light">Pamodzi has a unique history. In 1979, Ndaba Ntsele, Solly Sithole and the late Ncedi Manyoni founded
            the
            company at a
            time when black entrepreneurs in South Africa were barred from owning businesses, particularly in mainstream
            sectors. Pamodzi defied apartheid to become a noteworthy and successful business with a strong empowerment
            ethos.</p>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutRoots"
}
</script>

<style scoped>

</style>
