<template>
  <div class="container ">
    <div class="row my-3">
      <div class="col">
        <div class="text py-md-5 pt-3">
          <p>
            {{ item.txt }}
          </p>
        </div>
        <div class="pb-md-5 pb-5">
          <div class="row">
            <div class="col-12">
              <label class="pr-1" for="website">Website:</label>
              <a id="website" target="_blank" v-bind:href="item.website"> {{ item.website }}</a>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label class="pr-1" for="email">Email Address: </label>
              <a id="email" v-bind:href="'mailto:' + item.email"> {{ item.email }}</a>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label class="pr-1" for="number">Tel Num: </label>
              <a id="number" v-bind:href="'tel:'+item.number">{{ item.number }}</a>
            </div>
          </div>
                    <div class="row">
            <div class="col-12" v-if="item.id == 'Pamodzi Petroleum'">
              <label class="pr-1" for="website">Request A Quote:</label>
              <a id="website"  target="_blank" v-bind:href="item.docsLink"> {{ item.docsLink }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="row middle-block">-->
<!--      <div class="col-md-6 p-0">-->
<!--        <div class="">-->
<!--          <img class="img-fluid w-100"-->
<!--               src="../../assets/images/subsidiaries/pexels-pixabay-60008.png"-->
<!--               alt=""-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-md-6 p-0">-->
<!--        <div class="grey-box p-md-5 p-3">-->
<!--          <h3 class="header">-->
<!--            Lorem ipsum dolor sit amet-->
<!--          </h3>-->
<!--          <div class="text pt-md-5 pt-3">-->
<!--            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam-->
<!--            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam-->
<!--            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores-->
<!--            et ea rebum. Stet-->
<!--          </div>-->
<!--          <div class="">-->
<!--            <router-link to="/contact-us">-->
<!--              <button-->
<!--                  class="btn mt-md-5 mt-3 rounded-pill px-3 text-white pamodzi-red-background"-->
<!--              >-->
<!--                Contact Us-->
<!--              </button>-->
<!--            </router-link>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "SubsidiariesInfo",
  props: ["selectedId"],
  data() {
    return {
      item: {},
      items: [
        {
          email: "sales@uniqueeng.co.za",
          website: "https://www.uniqueeng.co.za",
          number: "011 826 6111",
          id: "Pamodzi Unique Engineering",
          txt:
              "Pamodzi Unique Engineering, PUE, is a niche design and manufacturing firm that specialises in mining, transport and industrial supplies for both domestic and international markets. PUE has the design expertise, flexibility and manufacturing capacity to deliver high quality products. As a leader in its chosen markets the firm applies the latest AutoCAD and Autodesk Inventor 3D computer modelling systems for research and development of customer solutions and product innovation and offers customised solutions to customers. Certified since 1992, the firm is ISO 9001:2015 and ISO 3834-2 accredited. PUE follows are structured Total Quality Management System and high quality safety standards.",
        },
        {
          email: "info@pamodzi.co.za",
          website: "https://www.pamodzismart.co.za",
          number: "010 072 2000",
          id: "Pamodzi Smart",
          txt: `Pamodzi Smart Store is an innovative mass distribution wholesaler and retailer that provides merchants with a comprehensive, scalable and modular end to end business solution. This business solution enhances smart stores’ operations by providing access to business data, trading patterns, better cash management, access to banking infrastructure, fosters quicker, convenient and affordable customer experience as well as providing sustainable employment and profitability. Pamodzi Smart Store specialises in providing high quality grocery and household products while using the power of technology as a catalyst to enhance and unleash business potential in African townships.`,
        },
        {
          email: "info@pamodzi.co.za",
          website: "https://www.pamodzi.co.za",
          number: "010 072 2000",
          id: "Pamodzi Petroleum",
          docsLink: "https://forms.gle/umGh62XmhgBoKWDN9",
          txt: `Pamodzi Petroleum is an integrated petroleum wholesale and distribution company targeting diverse markets in agriculture, construction, transport, manufacturing and the public sector. The company’s vision is to become a supplier of choice to discerning customers looking for consistent quality professional service while its mission is to leverage the Group’s proven deal-making expertise and brand integrity to optimise its investment in the sector. By partnering with credible delivery partners and institutions to augment its developed internal systems, processes and people, Pamodzi Petroleum provides products and services that exceed customers’ expectations. The long-term strategic goal is to operate storage terminals, distribute throughout the subcontinent and become the industry’s leading importer of all petroleum related products.`,
        },
        {
          email: "pannonia@pannonia.co.za",
          website: "https://www.pannonia.co.za",
          number: "011 918 4935",
          id: "Pamodzi Pannonia",
          txt: `Pamodzi Pannonia manufactures quality precision engineered products for a select clientele such as PM box scanning shafts, cable connectors, cable coupling, cutters, cable clamps, eco cavity pumps and wilfo double diaphragm pumps for the domestic industrial, aviation and mining markets. Machining for customer orders is produced according to custom designs using CAD-CAM system together with sophisticated computerised measuring and testing equipment provides final products of exceptional quality that the market has come to expect.`,
        },
        {
          email: "info@pamodzi.co.za",
          website: "https://www.pamodzi.co.za",
          number: "010 072 2000",
          id: "Pamodzi Africa Defence Group",
          txt:
              "Pamodzi Unique Engineering, PUE, is niche design and manufacturing firm that specialises in mining, transport and industrial supplies for both domestic and international markets. PUE has the design expertise, flexibility and manufacturing capacity to deliver high quality products. As a leader in its chosen markets the firm applies the latest AutoCAD and Autodesk Inventor 3D computer modelling systems for research and development of customer solutions and product innovation and offers customised solutions to customers. Certified since 1992, the firm is ISO 9001:2015 and ISO 3834-2 accredited. PUE follows are structured Total Quality Management System and high quality safety standards.",
        },
        {
          email: "info@pamodzi.co.za",
          website: "https://www.pamodzi.co.za",
          number: "010 072 2000",
          id: "Pamodzi FAW + Fleet Management",
          txt:
              "Pamodzi is in partnership with FAW, one of the largest manufacturers of commercial and military trucks in China, to form Pamodzi FAW. Pamodzi FAW is a yellow fleet supply, marketing and maintenance company with clients in the industrial, construction, military and public sector space. Its customised solutions helps clients focus on their core business knowing that their transport and maintenance needs are met by responsive professionals.",
        },
        {
          email: "info@pamodzi.co.za",
          website: "https://www.pamodzi.co.za",
          number: "010 072 2000",
          id: "Pamodzi Technologies",
          txt: `Pamodzi Technologies provides end to end technology solutions to FinTech and Smart City service providers and end-user customers through partnerships with leading organisations in a variety of technology segments. Resourced with highly skilled technologists specialising in software development and integration the division is taking advantage of the migration of the African people across the digital divide. Digitisation on the continent presents opportunities in digital integration, software development, hardware development and manufacturing.`,
        },
        {
          email: "info@pamodzi.co.za",
          website: "https://www.pamodzi.co.za",
          number: "010 072 2000",
          id: "Pamodzi Green Energy",
          txt:
              "Energy security is at the heart of ensuring a prosperous and sustainable future for the African Continent. Pamodzi Green Energy’s investment in renewable energy plants, namely biogas, CNG, PV and wind, will provide alternative sources of energy to power the Continent to meet its development goals. Pamodzi Green Energy specialises in project development, design, funding, EPC and operation of renewable energy facilities in Southern Africa to future energy needs. Boasting an experienced team of renewable energy experts, Pamodzi Green Energy aims to position itself as a leading independent power producer.",
        },
        {
          email: "info@pamodzi.co.za",
          website: "https://www.pamodzi.co.za",
          number: "010 072 2000",
          id: "Pamodzi Resources",
          txt: `Having started in an advisory role, Pamodzi Resources evolved to become a Fund with more than R1 billion assets under management in resources and mine-to-infrastructure projects in Sub-Saharan Africa to an indirect 27% equity holder in Anglo American Inyosi Coal, AAIC. AAIC is a fully empowered South African company with a resource base of over 4 billion tons.`,
        },
        {
          email: "info@pamodzi.co.za",
          website: "https://www.pamodzi.co.za",
          number: "010 072 2000",
          id: "Pamodzi rail",
          txt: `Pamodzi Rail is a rolling stock manufacturing and repair facility that services not only the needs of the South African Rail Sector but set its sights on African Continent. The Continent is facing railways infrastructure and the performance challenges that impede growth and sustainable development. Pamodzi Rail provides end to end solution that include railway lines, buildings and equipment, land located on railway premises and rail traffic systems.`,
        },
        {
          email: "lindiwe@pamodzi.co.za",
          website: "https://www.pamodzi.co.za",
          number: "010 072 2000",
          id: "Phephani Tyre Tech",
          txt:"Pamodzi Phephani Tyre Tech, PPTT, manufactures unique yet simple life saving safety bands for all modes of road and off-road vehicles. The safety bands are fitted in the well of a wheel rim preventing the tyre from slipping off the rim to keep the vehicle’s stability in the event of a tyre blowout, tyre burst or landing in a dangerous pothole. The safety band prevents unnecessary and unsafe stopping, allowing the driver to travel to the nearest place of safety, for up to 80km at a reduced speed of 50km/h. Phephani is an isiZulu term meaning “keep safe”; it provides drives with the security and assurance that their lives are safe in the event of a tyre emergency. It protects the environment from degradation and waste. PPTT technology has been subjected to stringent tests at Gerotek on behalf of the Department of Transport and the South African Bureau of Standards (SABS). "        },
                {
          email: "info@pamodzi.co.za",
          website: "https://www.pamodzi.co.za",
          number: "010 072 2000",
          id: "PRES",
          txt: "Pamodzi Revenue and Energy Solutions, PRES, introduces innovative solutions that enable both the private and public sectors to collect outstanding revenues. Through its customer care centre utilising world-class technology PRES is able to provide customers with collections, inbound and outbound customer service support and sales capability. PRES is also a leading supplier of electricity and water smart meters, a vital tool for municipalities with ambitions to proactively manage electricity distribution, water consumption as well as manage revenue flow."
        },
      ],
    };
  },
  beforeMount() {
    this.item = this.items.filter(x =>  x.id == this.selectedId)[0]
  },
  methods: {},
  watch: {
    "$route.params": {
      handler: function (params) {
        console.log(params);
        if (this.$route.name === "subsidiaries" && params.id) {
          this.item = this.items.filter((x) => x.id == params.id)[0];
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.txt {
  text-align: left;
}

.grey-box {
  background: #d0d0d0 0% 0% no-repeat padding-box;
  font: Open Sans;
}

.header {
  font: normal normal normal 15px/20px Open Sans;
}

.middle-block {
  img, .grey-box {
    height: 442px;
  }

}

</style>
