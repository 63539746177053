<template>
  <div class="">
<!--    <NavATF route-id="contact-us"></NavATF>-->
    <ContactForm class=""></ContactForm>
  </div>
</template>

<script>
// import NavATF from "@/components/shared/NavATF";
import ContactForm from '../components/contact/ContactUs.vue';

export default {
  name: "ContactUs",
  components: { ContactForm},
  created() {
    document.title = "Pamodzi | Contact Us"
  }
}
</script>

<style scoped lang='scss'>
    .position-absolute {
      top: 70%;
      right: 2%;
    }
</style>
