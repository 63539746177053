<template>
  <div class="container my-5">
    <div class="row">
      <div v-if="feedData==[]" class="">
        <div class="spinner-grow spinner-grow-lg" role="status">
          <span class="sr-only">Loading...</span>
        </div>      </div>
      <div v-else class="col-md-4 p-3" v-for="n in feedData.slice(0, 9)" v-bind:key="n.link">
        <a target="_blank" :href=n.link>
          <div class="">
            <div class="sub-item">
              <div class="img">
                <img
                    :src="n.enclosure['@attributes'].url"
                    alt=""
                    class="img-fluid w-100"
                />
              </div>
              <div class="p-3">
                <p class="text-dark">{{ n.title }}</p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>

const axios = require('axios').default;


export default {
  name: "Demo",
  components: {},
  data() {
    return {
      feedData: [],
      feedUrl: "https://rss.app/feeds/gSUBjAHjZaC1ZbNp.xml",
      name: "",
      limit: 4,
    }
  },
  methods: {
    fetchData: function () {
      axios.get(process.env.VUE_APP_ROOT_API + '/index.php')
          .then((response) => {
            this.feedData = response.data.channel.item;
            this.logResults(this.feedData);
          })
    },
    logResults: function (name, res) {
      console.log(name, res)
    }
  },
  mounted() {
    this.logResults(process.env.VUE_APP_ROOT_API + '/api/index.php', null)
    this.fetchData();
  }

}
</script>

<style>

</style>
