<template>
  <div class="">
    <NavATF route-id="team"></NavATF>
    <TeamExecutives></TeamExecutives>
  </div>

</template>

<script>
import NavATF from "@/components/shared/NavATF";
import TeamExecutives from "@/components/team/TeamExecutives";

export default {
  name: "Team",
  components: {TeamExecutives, NavATF},
  created() {
    document.title = "Pamodzi | Team"
  }
}
</script>

<style scoped>

</style>
