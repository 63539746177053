<template>
  <div class="pamodzi-grey-backgropund">
    <div class="container">
      <!--    Small Screens-->
      <div class="d-md-none p-1">
        <div id="timeline-content">
          <!--        <h1 class="text-dark">Our Roots</h1>-->
          <ul class="timeline text-dark">
            <li class="event" data-date="1979">
              <h3 class="font-weight-bold">1979 : The beginnning</h3>
              <p>Ntsele, Sithole and Manyoni initially named their company Inter-Marketing Enterprises only to land on a
                more appropriate name on a visit to Zambia: ‘Pamodzi’, a Kiswahili word meaning “togetherness”. The new
                name encapsulates their vision for the company: the development and growth of the collective. Pamodzi
                Property Construction (PPC) is born, funded by the trio’s personal endowment policies.</p>
            </li>
            <li class="event" data-date="1980s">
              <h3 class="font-weight-bold">1980s: Laying a strong foundation</h3>
              <p>A massive housing shortage in Soweto presented an opportunity to the company to build what was then
                called “two room and a garage” in the townships. No new houses were being built by the government for
                township residents, creating demand for supplementary living spaces and home extensions. Pamodzi soon
                begins to construct and sell three bedroom houses in Soweto, on land bought from the Urban Foundation.
                The
                company also locates a building society which can grant residential loans to potential clients. In
                partnership with a well-known construction company, Pamodzi ventures into petrol stations and helps to
                develop Soweto, Tshwane and sections of Ekurhuleni. School construction is another focus area for
                Pamodzi.
                The group receives a recognition award for the construction of Seana Marena Secondary School in
                Soweto.</p>
            </li>
            <li class="event" id="date" data-date="1990s">
              <h3 class="font-weight-bold">1990s: Reaching higher</h3>
              <p>A dawning democracy in the early 1990s presents new opportunities for South African businesses,
                particularly black entrepreneurs. Ntsele and Sithole take the opportunity to approach Nike International
                in 1993 with a plan to launch the global sportswear brand in southern Africa. Within three years, Nike
                South Africa secures its brand dominance on the continent. In 1995, Nike South Africa sponsors the
                victorious Springbok national team during that year’s Rugby World Cup, in a move which marked the
                country’s return to international sport. The Nike Care Trust, which develops sporting facilities in
                underprivileged communities, also builds Sebokeng’s iconic Chris Hani Sports Complex. In 1996, Ntsele
                and
                Sithole, together with a group of professionals set up Pamodzi Investment Holdings (PIH), which later
                becomes a serious player in the South African economy. A string of acquisitions soon follows: 20% of
                private hospital group Auckland Investments, South Africa’s largest fleet management solutions company,
                WesBank First Auto (25.1%), leading BMW dealership Auto Bavaria Motors (49%), JSE-listed IT company
                Digicore (25.1%), and 45% of Sodexho Southern Africa (Pty) Ltd. Additional investments include
                short-term
                insurance company Prestasi Brokers, now known as Indwe Risk following a merger with Thebe a leading
                sports
                marketing, sponsorship, hospitality and rights-acquisition business. Pamodzi Sports Marketing and
                Pamodzi
                Leisure Solutions which owns the five-star Tinga Game Park near the Kruger National Park. In 1998
                Pamodzi
                purchases 51% of food manufacturing, marketing and distribution company FoodCorp for R1.9 billion,
                giving
                it control of leading brands such as Yum Yum peanut butter, Ouma Rusks, Nola mayonnaise and Glenryck
                canned pilchards. This becomes the largest management buyout in South Africa.</p>
            </li>
            <li class="event" data-date="2000s">
              <h3 class="font-weight-bold">2000s: Leading the market</h3>
              <p>In 2003, the company acquires 30% of world-class stockist, distributor and processor of stainless steel
                Kulungile Metals (Pty) Ltd, formerly known as Stalcor. The following year, FoodCorp raises the first
                high-yield Eurobond by a South African company – and the largest secondary buyout at the time. Pamodzi
                also buys 28% in Namitech Holdings that same year. In 2007, Pamodzi launches Africa’s biggest private
                equity initiative, the Pamodzi Resources Fund with US$1.3 billion from US investors. That same year,
                subsidiary Pamodzi Coal purchases a 26% stake in Anglo Coal, through the broad-based black economic
                empowerment consortium Inyosi. The resulting R7.4 billion company, Anglo Inyosi Coal, is a fully
                empowered
                South African company with a resource base of over four billion tons of coal. Today, Pamodzi is staying
                true to its history, and continuing to actively seek out new opportunities while introducing a new
                philosophy for business innovation. </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutTimeline"
}
</script>

<style scoped>
/* Import */
@font-face {
  font-family: 'Chivo';
  font-style: italic;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/chivo/v12/va9D4kzIxd1KFrBteUp9gK_uQQ.ttf) format('truetype');
}

@font-face {
  font-family: 'Chivo';
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/chivo/v12/va9G4kzIxd1KFrBtceFfkA.ttf) format('truetype');
}

@font-face {
  font-family: 'Chivo';
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/chivo/v12/va9D4kzIxd1KFrBteVp6gK_uQQ.ttf) format('truetype');
}

@font-face {
  font-family: 'Chivo';
  font-style: italic;
  font-weight: 900;
  src: url(https://fonts.gstatic.com/s/chivo/v12/va9D4kzIxd1KFrBteWJ4gK_uQQ.ttf) format('truetype');
}

@font-face {
  font-family: 'Chivo';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/chivo/v12/va9F4kzIxd1KFrjDY_Z4sKg.ttf) format('truetype');
}

@font-face {
  font-family: 'Chivo';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/chivo/v12/va9I4kzIxd1KFrBoQeY.ttf) format('truetype');
}

@font-face {
  font-family: 'Chivo';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/chivo/v12/va9F4kzIxd1KFrjTZPZ4sKg.ttf) format('truetype');
}

@font-face {
  font-family: 'Chivo';
  font-style: normal;
  font-weight: 900;
  src: url(https://fonts.gstatic.com/s/chivo/v12/va9F4kzIxd1KFrjrZvZ4sKg.ttf) format('truetype');
}

@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 100;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/saira/v7/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA71rDks8xkw.ttf) format('truetype');
}

@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 200;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/saira/v7/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA79rCks8xkw.ttf) format('truetype');
}

@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/saira/v7/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA7wTCks8xkw.ttf) format('truetype');
}

@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/saira/v7/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA71rCks8xkw.ttf) format('truetype');
}

@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/saira/v7/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA72jCks8xkw.ttf) format('truetype');
}

@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/saira/v7/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA74TFks8xkw.ttf) format('truetype');
}

@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/saira/v7/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA773Fks8xkw.ttf) format('truetype');
}

@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/saira/v7/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA79rFks8xkw.ttf) format('truetype');
}

@font-face {
  font-family: 'Saira Extra Condensed';
  font-style: normal;
  font-weight: 100;
  src: url(https://fonts.gstatic.com/s/sairaextracondensed/v6/-nFsOHYr-vcC7h8MklGBkrvmUG9rbpkisrTri3j2_Co.ttf) format('truetype');
}

@font-face {
  font-family: 'Saira Extra Condensed';
  font-style: normal;
  font-weight: 200;
  src: url(https://fonts.gstatic.com/s/sairaextracondensed/v6/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrJ2nh2wpk.ttf) format('truetype');
}

@font-face {
  font-family: 'Saira Extra Condensed';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/sairaextracondensed/v6/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrQ2rh2wpk.ttf) format('truetype');
}

@font-face {
  font-family: 'Saira Extra Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/sairaextracondensed/v6/-nFiOHYr-vcC7h8MklGBkrvmUG9rbpkisrTj6Ejx.ttf) format('truetype');
}

@font-face {
  font-family: 'Saira Extra Condensed';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/sairaextracondensed/v6/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrG2vh2wpk.ttf) format('truetype');
}

@font-face {
  font-family: 'Saira Extra Condensed';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/sairaextracondensed/v6/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrN2zh2wpk.ttf) format('truetype');
}

@font-face {
  font-family: 'Saira Extra Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/sairaextracondensed/v6/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrU23h2wpk.ttf) format('truetype');
}

@font-face {
  font-family: 'Saira Extra Condensed';
  font-style: normal;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/sairaextracondensed/v6/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrT27h2wpk.ttf) format('truetype');
}

/* Variables */
/* Base */
body {
  background: #252827;
  font-size: 16px;
}

p {
  font-weight: 300;
}

a {
  color: #6c6d6d;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  letter-spacing: 0.3em;
  font-size: 0.6em;
  font-weight: 400;
  background: #252727;
  padding: 0.3rem 1rem;
  margin: 1.9rem 0 0 0;
  float: right;
}

a:hover {
  color: white;
  background: #004ffc;
  border-bottom: 0.35em solid black;
}

strong {
  font-weight: 600;
}

h1 {
  /*font-family: 'Saira', sans-serif;*/
  letter-spacing: 1.5px;
  color: white;
  font-weight: 400;
  font-size: 2.4em;
}

#timeline-content {
  margin-top: 50px;
  text-align: left;
}

/* Timeline */
.timeline {
  border-left: 4px solid #000000;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  /*background: rgba(255, 255, 255, 0.03);*/
  /*color: rgba(255, 255, 255, 0.8);*/
  /*font-family: 'Chivo', sans-serif;*/
  /*margin: 50px auto;*/
  /*letter-spacing: 0.5px;*/
  position: relative;
  line-height: 1.4em;
  font-size: 16px;
  padding: 25px 25px 25px 50px;
  list-style: none;
  text-align: left;
  font-weight: 100;
  /*max-width: 30%;*/
}

.timeline h1 {
  /*font-family: 'Saira', sans-serif;*/
  font-weight: 100;
  font-size: 1.4em;
}

.timeline h2,
.timeline h3 {
  /*font-family: 'Saira', sans-serif;*/
  font-weight: 400;
  text-transform: uppercase; /* Transforms all characters to uppercase */
}

.timeline .event {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
  padding-bottom: 25px;
  margin-bottom: 50px;
  position: relative;
}

.timeline .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline .event:before,
.timeline .event:after {
  position: absolute;
  display: block;
  top: 0;
}

/*.timeline .event:before {*/
/*  left: -217.5px;*/
/*  !*color: rgba(255, 255, 255, 0.4);*!*/
/*  content: attr(data-date);*/
/*  text-align: right;*/
/*  font-weight: 100;*/
/*  font-size: 0.9em;*/
/*  min-width: 120px;*/
/*  font-family: 'Saira', sans-serif;*/
/*}*/

.timeline .event:after {
  box-shadow: 0 0 0 4px #000000;
  left: -57.85px;
  background: #313534;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
  top: 5px;
}

/* Setting fade transition and default settings */
body {
  color: #000;
  background-color: #f4f4f4;
  transition: background-color 1s ease;
}

/* panel styles */
.panel {
  /* min height incase content is higher than window height */
  min-height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: sans-serif;
  /* outline: 10px solid hotpink; */
  /* turn above on to see the edge of panels */
}

/* colours */
.color-violet {
  background-color: #7A4EAB;
}

.color-indigo {
  background-color: #4332CF;
}

.color-blue {
  background-color: #2F8FED;
}

.color-green {
  background-color: #4DCF42;
}

.color-yellow {
  background-color: #FAEB33;
}

.color-orange {
  background-color: #F19031;
}

.color-red {
  background-color: #F2293A;
}

/* styling for demo, can ignore */
body {
  text-align: center;
  font-size: 120%;
  line-height: 1.618;
}

h1, h2 {
  font-size: 3em;
  letter-spacing: -0.05em;
  line-height: 1.1;
}

p {
  max-width: 30em;
  margin-bottom: 1.618em;
}

a {
  color: #4332CF;
}

</style>
