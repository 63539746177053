<template>
  <div class="">
    <NavATF route-id="news" ></NavATF>
    <NewsRssFeed></NewsRssFeed>
    <CurrencyCarousel></CurrencyCarousel>
  </div>
</template>

<script>

import NavATF from "@/components/shared/NavATF";
import NewsRssFeed from "@/components/news/NewsRssFeed";
import CurrencyCarousel from "@/components/news/CurrencyCarousel";

export default {
  name: "News",
  components: {CurrencyCarousel, NewsRssFeed, NavATF},
  created() {
    document.title = "Pamodzi | News"
  }
}
</script>

<style scoped>

</style>
