<template>
  <div class="">
    <NavATF :route-id="selectedId"></NavATF>
    <div class="row">
      <div class="col">
      </div>
    </div>
  </div>
</template>

<script>
import NavATF from '../shared/NavATF.vue'

export default {
  components: {NavATF},
  props: ['selectedId'],
  name: "SubsidiariesATF"
}
</script>

<style scoped lang="scss">

</style>
