<template>
  <div v-if="selectedNav.about != 'none'" class="">
    <div class="">
      <div class="container">
        <h2 class="h1">About</h2>
        <p class="font-weight-light" v-html="selectedNav.about"></p>
      </div>
    </div>
  </div>

</template>

<script>
import teamData from '../../../assets/info/teams.json'
export default {
name: "TeamMemberAbout",
 props: {
    routeId: String,
  },
  data() {
    return {
      selectedNav: {},
      teamData
    }
  },
  mounted() {
    this.selectedNav = this.teamData.filter((a) => a.id === this.routeId)[0];
  },
}
</script>

<style scoped>

</style>
