<template>
  <div v-if="selectedNav.achievements != 'none'" class="">
    <div class="">
      <div class="container">
        <h2 class="h1">Achievements</h2>
          <p class="font-weight-light" v-html="selectedNav.achievements">
          </p>
      </div>
    </div>
  </div>
</template>

<script>
import teamData from '../../../assets/info/teams.json'
export default {
  name: "TeamMemberAchievements",
   props: {
    routeId: String,
  },
  data() {
    return {
      selectedNav: {},
      teamData
    }
  },
  mounted() {
    this.selectedNav = this.teamData.filter((a) => a.id === this.routeId)[0];
  },
}
</script>

<style scoped>

</style>
