<template>
  <div class="">
    <NavATF route-id="group" ></NavATF>
  </div>
</template>

<script>
import NavATF from "@/components/shared/NavATF";

export default {
  name: "Group",
  components: {NavATF},
  created() {
    document.title = "Pamodzi | Subsidiaries"
  }
}
</script>

<style scoped>

</style>
