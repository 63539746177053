<template>
  <div class="bg-light">
    <div class="container h-100 p-5">
      <div class="row h-100 justify-content-center align-items-center">
        <div class="form-group">
          <blockquote class="blockquote text-center">
            <img
              src="../../assets/images/shared/coming-soon.svg"
              class="w-100 img-responsive"
              alt="Coming Soon"
            />
          </blockquote>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComingSoon",
};
</script>

<style scoped></style>
