<template>
  <div class="">
    <SubsidiariesATF :selectedId="sentID"></SubsidiariesATF>
    <SubsidiariesInfo :selectedId="sentID"></SubsidiariesInfo>
    <SubsidiariesMore :selectedId="sentID"></SubsidiariesMore>
  </div>
</template>

<script>
import SubsidiariesInfo from "@/components/subsidiaries/SubsidiariesInfo";
import SubsidiariesMore from "@/components/subsidiaries/SubsidiariesMore";
import SubsidiariesATF from "@/components/subsidiaries/SubsidiariesATF";


export default {
  name: "SubsidiariesDetails",
  data() {
    return {
      sentID: ''
    }
  },
  components: {SubsidiariesInfo, SubsidiariesATF, SubsidiariesMore},
  beforeMount() {
    this.sentID = this.$route.params.id
  },
  watch: {
    "$route.params": {
      handler: function(params) {
        if (this.$route.name === "subsidiaries") {
          this.sentID = params.id;
          if (params.id) {
            console.log("goto sub/", params.id);
            this.notMain = params.id !== "subsidiaries";
          } else {
            this.notMain = false;
          }
        }
      },
      deep: true,
    },
  },
  created() {
    document.title = "Pamodzi | " + this.$route.params.id
  }
}
</script>

<style scoped>

</style>
