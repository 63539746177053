<template>
  <div class="pamodzi-black-backgropund text-white font-weight-light background">
    <div class="container pt-md-5 pt-3">
      <div class="row text-center text-wrap font-weight-light">
        <p>
          Pamodzi stands for “Togetherness” in an African sense. To us Pamodzi presents collective growth; the ripple
          and
          multiplying effect that comes with working together for success by combining the diverse abilities of our
          people
          to achieve a bigger goal.
        </p>
      </div>
      <div class="row">
        <div class="col-md-6 p-2">
          <i class="bi bi-eye pamodzi-cream"></i>
          <h3 class="text-white">
            Our Vision
          </h3>
          <p>
            To be a conglomerate with an entrepreneurial flair that benefits all its stakeholders.
          </p>
        </div>
        <div class="col-md-6 p-2">
          <i class="bi bi-bullseye pamodzi-cream"></i>
          <h3 class="text-white">
            Mission
          </h3>
          <div class="font-weight-light">
            <p>
              We invest in growth sectors. In so doing, we leverage our proven deal-making expertise and brand integrity
              to optimise our investments. </p>
            <p> We partner with credible delivery partners and institutions to augment our
              highly developed internal systems, processes and human resources to provide products and services that
              exceed our customers’ expectations. We aim to attract, develop and retain the best talent in business as
              well as create a conducive culture for optimum performance.</p>
            <p>
              Our goal is to always sustainably optimise
              return on investment for our shareholders whilst making a positive impact on the lives of all our
              stakeholders.
            </p>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="w-100 text-center my-auto">
          <carousel perPage="1">
            <slide>
              Slide 1 Content
            </slide>
            <slide>
              Slide 2 Content
            </slide>
          </carousel>
<!--          <carousel>-->
<!--            <slide>-->
<!--              <div class="px-2 bannerBackground my-auto">-->
<!--                <div class="pt-3">-->
<!--                  <h4>Excellence</h4>-->
<!--                  <p>-->
<!--                    We strive to build world-class companies and outperform industry peers.-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </slide>-->
<!--            <slide>-->
<!--              <div class="px-3">-->
<!--                <div class="pt-3">-->
<!--                  <h4>Commitment and Dedication</h4>-->
<!--                  <p>-->
<!--                    Our work is not over until we realise sustainable returns.-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </slide>-->
<!--            <slide>-->
<!--              <div class="px-3">-->
<!--                <div class="pt-3">-->
<!--                  <h4>Commitment and Dedication</h4>-->
<!--                  <p>-->
<!--                    Our work is not over until we realise sustainable returns.-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </slide>-->
<!--          </carousel>-->

          <!--          <splide class="svg">-->
          <!--            <splide-slide>-->
          <!--              <div class="px-2 bannerBackground my-auto">-->
          <!--                <div class="pt-3">-->
          <!--                  <h4>Excellence</h4>-->
          <!--                  <p>-->
          <!--                    We strive to build world-class companies and outperform industry peers.-->
          <!--                  </p>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </splide-slide>-->
          <!--            <splide-slide>-->
          <!--              <div class="px-3">-->
          <!--                <div class="pt-3">-->
          <!--                  <h4>Commitment and Dedication</h4>-->
          <!--                  <p>-->
          <!--                    Our work is not over until we realise sustainable returns.-->
          <!--                  </p>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </splide-slide>-->
          <!--            <splide-slide>-->
          <!--              <div class="px-2">-->
          <!--                <div class="pt-3">-->
          <!--                  <h4>-->
          <!--                    Integrity-->
          <!--                  </h4>-->
          <!--                  <p>-->
          <!--                    We approach every business with the intention to make it successful. We are accountable, fair and-->
          <!--                    consistent in our actions; open in our communication and have respect for our stakeholders.-->
          <!--                  </p>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </splide-slide>-->
          <!--          </splide>-->
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>

import Carousel from 'vue-carousel'
import Slide from 'vue-carousel'

export default {
  name: "AboutMissionAndVision",
  components:{
    Carousel,
    Slide
  },
  data() {
    return {
      settings: {
        // "dots": true,
        // "dotsClass": "slick-dots custom-dot-class",
        // "edgeFriction": 0.35,
        // "infinite": false,
        // "speed": 500,
        // "slidesToShow": 1,
        // "slidesToScroll": 1
      }
    }
  }

}
</script>

<style scoped>
.background {
  background-position: 20% 20px;
  background-image: url("../../assets/images/shared/backgroundLogo.svg");
  background-repeat: no-repeat;
}

.bi {
  font-size: 50px;
}

.bannerBackground {
  height: 100%;
  background-image: url("../../assets/images/about-us/valuesBanner.png");
  background-repeat: no-repeat;
  width: 100%;
}

.svg {
  fill: #d0d0d0 !important;
}

.splide__arrow {
  fill: #d0d0d0 !important;
}

</style>
